<template>
  <div>
    <center>
      <v-card
        width="80%"
        max-width="500px"
        style="margin-bottom: 50px; margin-top: 80px"
      >
        <v-system-bar color="orange darken-1" />
        <v-card-title>
          <v-row justify="center" style="margin: 10px auto">
            <h3>{{ user.auth.email }} さん</h3>
          </v-row>
        </v-card-title>
        <v-card-text>
          <div style="max-width: 300px">
            <!-- 学習管理対象者のみ表示する -->
            <div v-if="vifStudetnt">
              <font size="4">
                <p>所属：{{ user.claims.companyName }}</p>
                <p>グループ:{{ user.claims.groupName }}</p>
              </font>
              <!--
                <v-btn width="90%" text flat rounded color="grey" @click="leaveCompany()">
                    <b>所属を解除する</b>
                </v-btn>
              -->
            </div>
            <!-- メアド・パスワードの変更はメールアカウントのみ実行できる -->
            <div v-if="vifMailAcoount">
              <!-- メアドの変更 -->
              <v-row justify="center" style="margin: 30px auto">
                <v-btn
                  width="90%"
                  outlined
                  rounded
                  style="text-transform: none"
                  @click="
                    showUpdateEmail = !showUpdateEmail;
                    newEmail = '';
                  "
                >
                  <b>メールアドレスの変更</b>
                </v-btn>
              </v-row>
              <!-- パスワードの変更 -->
              <v-row justify="center" style="margin: 30px auto">
                <v-btn
                  width="90%"
                  outlined
                  rounded
                  style="text-transform: none"
                  @click="
                    showUpdatePass = !showUpdatePass;
                    newPassword = '';
                    confirmPassword = '';
                  "
                >
                  <b>パスワードの変更</b>
                </v-btn>
              </v-row>
            </div>
            <!-- ログアウト -->
            <v-row justify="center" style="margin: 30px auto">
              <v-btn
                width="90%"
                outlined
                rounded
                style="text-transform: none"
                @click="logout()"
              >
                <b>ログアウト</b>
              </v-btn>
            </v-row>
          </div>
        </v-card-text>
      </v-card>

      <!-- メアド変更ダイアログ -->
      <v-dialog v-model="showUpdateEmail" persistent max-width="500px">
        <v-card>
          <div style="margin: auto 5px; float: right">
            <a><v-icon @click="showUpdateEmail = false">mdi-close</v-icon></a>
          </div>
          <v-card-title>メールアドレスの変更</v-card-title>
          <v-card-text>
            <center>
              ・変更が完了するとログアウト状態になります。
              <div style="max-width: 300px">
                <v-row justify="center" style="margin: 10px auto">
                  <v-text-field
                    v-model="user.auth.email"
                    label="現在のメールアドレス"
                    disabled
                  ></v-text-field>
                </v-row>
                <v-row justify="center">
                  <v-text-field
                    v-model="newEmail"
                    label="新しいメールアドレス"
                    style="margin: 10px"
                  ></v-text-field>
                  <v-btn
                    width="90%"
                    outlined
                    rounded
                    @click="updateEmail()"
                    style="margin: 30px"
                  >
                    <b>メールアドレスを変更する</b>
                  </v-btn>
                </v-row>
              </div>
            </center>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- パスワード変更ダイアログ -->
      <v-dialog v-model="showUpdatePass" persistent max-width="500px">
        <v-card>
          <div style="margin: auto 5px; float: right">
            <a><v-icon @click="showUpdatePass = false">mdi-close</v-icon></a>
          </div>
          <v-card-title>パスワードの変更</v-card-title>
          <v-card-text>
            <center>
              ・変更が完了するとログアウト状態になります。
              <div style="max-width: 300px">
                <v-row justify="center" style="margin: 10px auto">
                  <v-text-field
                    v-model="user.auth.email"
                    label="メールアドレス"
                    disabled
                  ></v-text-field>
                </v-row>
                <v-row justify="center">
                  <v-text-field
                    v-model="newPassword"
                    label="新しいパスワード"
                    :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showNewPass ? 'text' : 'password'"
                    @click:append="showNewPass = !showNewPass"
                    hint="半角英数字6文字以上"
                    persistent-hint
                    style="margin: 10px"
                  ></v-text-field>

                  <v-text-field
                    v-model="confirmPassword"
                    label="確認のための入力"
                    :type="false ? 'text' : 'password'"
                    :rules="[rules.required, rules.isSame]"
                    style="margin: 10px"
                  ></v-text-field>
                  <v-btn
                    width="90%"
                    outlined
                    rounded
                    @click="updatePassword()"
                    style="margin: 30px"
                  >
                    <b>パスワードを変更する</b>
                  </v-btn>
                </v-row>
              </div>
            </center>
          </v-card-text>
        </v-card>
      </v-dialog>
    </center>
  </div>
</template>

<style></style>

<script>
import { mapState } from 'vuex';
export default {
  name: 'myAcount',
  components: {},
  computed: {
    ...mapState(['webApp', 'user']),
  },
  data() {
    return {
      vifStudetnt: false, //企業で学習管理されているユーザーには表示する
      vifMailAcoount: true, //メールアカウントの場合は、変更手段を表示する。
      //パスワード変更ダイアログ
      //------------------------------------------------------
      showUpdatePass: false, //パスワード変更ダイアログの表示
      showNewPass: false,
      newPassword: '',
      confirmPassword: '',
      rules: {
        required: (value) => !!value || '入力必須です',
        isSame: (value) => {
          if (this.newPassword == value) {
            return true;
          } else {
            return '一致していません';
          }
        },
      },
      //パスワード変更ダイアログ
      //------------------------------------------------------
      showUpdateEmail: false, //メアド変更ダイアログの表示
      newEmail: '',
    };
  },
  async mounted() {
    this.$store.commit('loading', true);
    //myAccountからログアウトして、ブラウザバックした時に弾く
    if (!this.gf_isLogin()) {
      this.logout();
      return;
    }

    //状態維持
    await this.gf_keepActive();
    await this.gf_keepLogin();

    await this.setUserInfo();
    this.$store.commit('loading', false);
  },
  methods: {
    //======================================================================================
    setUserInfo() {
      const providerId = this.user.auth.providerData[0].providerId;
      if (providerId != 'password') {
        //googleユーザーにはメアド・パスワードの変更は表示しない
        this.vifMailAcoount = false;
      }

      const companyId = this.user.claims.companyId;
      if (companyId) {
        this.vifStudetnt = true;
      }
    },
    async updatePassword() {
      if (this.newPassword == '' || this.confirmPassword == '') {
        alert('パスワードが入力されていません');
        return;
      }
      if (this.newPassword !== this.confirmPassword) {
        alert('確認用パスワードが一致していません');
        return;
      }
      if (!this.newPassword.match(/^[A-Za-z0-9]+$/)) {
        alert('パスワードが半角英数字になっていません');
        return false;
      }
      if (this.newPassword.length < 6) {
        alert('パスワードが6文字以上になっていません');
        return false;
      }
      this.$store.commit('loading', true);
      const isSuccess = await this.gf_updatePassword(this.newPassword);
      if (isSuccess) {
        alert('パスワードの変更が完了しました');
        this.$store.commit('loading', false);
        this.logout();
      }
    },
    async updateEmail() {
      const regex =
        /^[a-zA-Z0-9_.+-]+@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
      const isEmail = regex.test(this.newEmail);
      if (isEmail != true) {
        alert('不適切な文字列があります。メールアドレスをご確認ください。');
        return;
      }
      this.$store.commit('loading', true);
      const isSuccess = await this.gf_updateEmail(this.newEmail);
      if (isSuccess) {
        alert('メールアドレスの変更が完了しました');
        this.$store.commit('loading', false);
        this.logout();
      }
    },
    logout() {
      this.gf_signOut_uzcolletest().then(() => {
        this.$router.push('/');
      });
    },
    leaveCompany() {
      //いったん保留
    },
  },
};
</script>
